module.exports = {
  title: `sizplay`,
  description: `Blog posted about React, typescript and other developments`,
  author: `Chaehoon Lim`,
  introduction: `운동 코딩 맥주`,
  siteUrl: `https://gatsby-starter-bee.netlify.com`, // Your blog site url
  social: {
    twitter: `@sizplay_`, // Your Twitter account
    github: `sizplay`, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
  },
  icon: `content/assets/logo.png`, // Add your favicon
  keywords: [`blog`, `react`, `development`, `typescript`],
  comment: {
    disqusShortName: 'sizplays-blog', // Your disqus-short-name. check disqus.com.
    utterances: 'sizplay/myBlog', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: 'qWUiJ2n',
  },
  share: {
    facebookAppId: '1310012295836629', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: 'UA-151588016-1', // Add your google analytics tranking ID
}
